<template>
  <div>
    <v-row>
      <v-col lg="12">
        <div v-if="$slots.title" class="col"><slot name="title"></slot></div>
        <div class="graph-wrap checklist-wrap">
          <div class="d-flex flex-column">
            <div>{{ val }}<span>/{{ max_val }}</span></div>
            <p class="mt-auto mb-0">Чек-лист</p>
          </div>
          <div class="checklist">
            <template v-for="(parameter, parameterIndex) in checklistParameters">
              <div width="14" height="14"
                  v-if="checkParameter(parameter.indicatorId)"
                  :key="parameterIndex+'_par'"
              >
                <v-icon class="icon-item icon-check-bold"></v-icon>
                {{ parameter.text }}
              </div>
              <div v-else :key="parameterIndex+'_par'">
                {{ parameter.text }}
              </div>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "ChecklistStatus",
  components: {},
  props: ['checklistParameters', 'checklistValues', 'val', 'max_val'],
  data: () => ({}),
  methods: {
    checkParameter(indicatorId) {
      const value = this.checklistValues.find(e => e.indicatorId===indicatorId && e.type==='FILES')
      if(!value){
        return false
      }
      const files = JSON.parse(value.value)
      return files.length > 0
    }
  },
  beforeMount() {
  }
}
</script>

<style scoped>
.item-icon {
  min-width: 24px !important;
}
</style>
